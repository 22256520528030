import {
  IAppointmentLocationState,
  IAppointmentNote,
  IAppointmentQuestion,
  IAppointmentTimeSlot,
  IAppointmentType,
  IMemberAppointment
} from 'types';
import ApiService from './api-service';
import settings from 'constants/appsettings.json';

export class AppointmentService {
  protected apiService: ApiService;
  protected serviceScope: string[];

  constructor(apiService: ApiService) {
    this.apiService = apiService;
    this.serviceScope = settings.serviceScopes.AppointmentService;
  }

  getAppointments(memberId: number): Promise<IMemberAppointment[]> {
    return this.apiService.get(this.serviceScope, `appointments/v1/Appointments/${memberId}`).then((r) => {
      const data = r.data;

      const sortedData = data.sort((a: IMemberAppointment, b: IMemberAppointment) => {
        const aDate = new Date(a.appointmentDateTime!);
        const bDate = new Date(b.appointmentDateTime!);
        return aDate.getTime() - bDate.getTime();
      });

      return sortedData;
    });
  }

  getAppointmentNotes(appointmentId: number): Promise<IAppointmentNote[]> {
    return this.apiService.get(this.serviceScope, `appointments/v1/Appointments/${appointmentId}/notes`).then((r) => r?.data);
  }

  loadAppointmentQuestions(): Promise<IAppointmentQuestion[]> {
    return this.apiService.get(this.serviceScope, `appointments/v1/AppointmentComponents/questions`).then((r) => r?.data);
  }

  loadAppointmentTypes(): Promise<IAppointmentType[]> {
    return this.apiService.get(this.serviceScope, `appointments/v1/AppointmentComponents/types`).then((r) => r?.data);
  }

  loadAppointmentLocations(): Promise<IAppointmentLocationState[]> {
    return this.apiService.get(this.serviceScope, `appointments/v1/AppointmentComponents/locationStates`).then((r) => r?.data);
  }

  getAppointmentTimeslots(locationId: number, reasonId: number): Promise<IAppointmentTimeSlot[]> {
    return this.apiService
      .get(this.serviceScope, `appointments/v1/AppointmentComponents/timeslots/location/${locationId}?appointmentTypeId=${reasonId}`)
      .then((r) => r?.data);
  }
}

export default AppointmentService;
