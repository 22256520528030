import { IActivity } from 'types';
import ApiService from './api-service';
import settings from 'constants/appsettings.json';

interface PaginatedActivitiesList {
  activities: IActivity[];
  activityCount: number;
  pageSize: number;
  pageIncrement: number;
}

export class ActivityService {
  protected apiService: ApiService;
  protected serviceScope: string[];

  constructor(apiService: ApiService) {
    this.apiService = apiService;
    this.serviceScope = settings.serviceScopes.ActivityService;
  }

  getMyActivities(
    groupIds: string[],
    pageIncrement: number,
    pageSize: number,
    searchString?: string,
    filterTypes?: string[],
    filterStartDate?: string,
    filterEndDate?: string,
    filterByTeams: boolean = false
  ): Promise<PaginatedActivitiesList> {
    return this.apiService
      .post(this.serviceScope, `activity/v1/Activity/myActivities`, {
        groupIds,
        pageSize,
        pageIncrement,
        searchString,
        filterTypes,
        filterStartDate,
        filterEndDate,
        filterByTeams
      })
      .then((r) => r?.data);
  }

  getTaskActivities(taskId: string): Promise<IActivity[]> {
    return this.apiService.get(this.serviceScope, `activity/v1/Activity/${taskId}`).then((r) => r?.data);
  }
}

export default ActivityService;
