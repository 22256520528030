import { createSlice, current } from '@reduxjs/toolkit';
import { IMemberState, ITask, ITaskParticipant } from 'types';

const initialState: IMemberState = {
  isMemberLoading: false,
  isMemberTaskLoading: false,
  isMemberTaskUpdating: false,
  member: null,
  currentMemberTask: null,
  currentMemberTaskAdditionalFields: []
};

const slice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    isLoadingMember(state, action) {
      state.isMemberLoading = action.payload;
    },

    isLoadingMemberTask(state, action) {
      state.isMemberTaskLoading = action.payload;
    },

    isUpdatingMemberTask(state, action) {
      state.isMemberTaskUpdating = action.payload;
    },

    updatePortalAccess(state, action) {
      state.member!.memberPortalAccess = action.payload;
    },

    loadMember(state, action) {
      state.member = action.payload;
      state.isMemberLoading = false;
    },

    updateCurrentTaskParticipants(state, action) {
      const currentState = current(state);
      const currentParticipants = currentState.currentMemberTask?.participants as ITaskParticipant[];
      const updatedParticipants = action.payload.filter(
        (p: ITaskParticipant) => currentParticipants.find((cp) => cp.id === p.id) == null
      ) as ITaskParticipant[];

      const updatedCurrentMemberTask = {
        ...currentState.currentMemberTask,
        participants: [...currentParticipants, ...updatedParticipants]
      } as ITask;

      state.currentMemberTask = updatedCurrentMemberTask;
    },

    updateCurrentMemberTask(state, action) {
      state.currentMemberTask = action.payload;
      state.isMemberTaskUpdating = false;
      state.isMemberTaskLoading = false;
    },

    setCurrentMemberTask(state, action) {
      state.currentMemberTask = action.payload;
      state.isMemberTaskLoading = false;
      state.isMemberTaskUpdating = false;
    },

    resetMember(state) {
      state.member = null;
    },

    resetMemberState(state) {
      return {
        ...initialState
      };
    }
  }
});

export default slice.reducer;
export const {
  isLoadingMember,
  isLoadingMemberTask,
  isUpdatingMemberTask,
  loadMember,
  setCurrentMemberTask,
  updatePortalAccess,
  updateCurrentMemberTask,
  updateCurrentTaskParticipants,
  resetMember,
  resetMemberState
} = slice.actions;
