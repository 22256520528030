import { AxiosResponse, AxiosError } from 'axios';
import { createAxiosInstance } from '../axios';
import settings from 'constants/appsettings.json';
import { IRequestConfig } from 'types';
import { msalInstance } from 'contexts/Provider';
import { getAuthToken } from 'services/Auth/Auth.service';
import * as uuid from 'uuid';
import { store } from 'store';

const axiosClient = async (serviceScope: string[]) => {
  const accounts = msalInstance.getAllAccounts();
  if (!accounts || accounts.length === 0) {
    return null;
  }
  const account = accounts[0];

  const correlationId = uuid.v4();
  const messageId = uuid.v4();
  const authToken = await getAuthToken(msalInstance, account, serviceScope);
  const baseURL = settings.apiDetails.baseUri;

  return createAxiosInstance(store.dispatch, baseURL, authToken, correlationId, messageId);
};

const apiData = () => {
  return {
    processRequest: async (config: IRequestConfig) => {
      const axReq = await axiosClient(config.serviceScope);
      if (axReq) {
        const { url, data, onResolve, onReject, method = 'get' } = config;

        const params = {
          referrerPolicy: 'origin',
          credentials: 'include',
          url,
          data,
          method
        };

        let returnData: any;
        await axReq
          .request(params)
          .then((res: AxiosResponse) => {
            if (res.status > 399) {
              if (onReject) {
                onReject(res.data);
              }
              return res.data;
            }

            if (onResolve) {
              onResolve(res);
            }
            returnData = res.data;
          })
          .catch((err: AxiosError) => {
            const msg = err.message;
            if (onReject) {
              onReject(err.response);
            }
            returnData = msg;
          });
        return returnData;
      }
    }
  };
};

export default apiData;
