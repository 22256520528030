import { api } from 'utils';
import ApiService from './api-service';
import { IRequestConfig } from 'types';

//  This wraps the api utility.  Later on we can create an AxiosApiService that replaces this if we like (if we want to remove the complexity of the api util)
export class ApiDataApiService extends ApiService {
  static _apiService: ApiDataApiService;
  static create() {
    if (!ApiDataApiService._apiService) {
      ApiDataApiService._apiService = new ApiDataApiService();
    }
    return ApiDataApiService._apiService;
  }

  get(serviceScope: string[], url: string): Promise<any> {
    return new Promise((onResolve, onReject) => {
      const config: IRequestConfig = {
        method: 'get',
        url,
        serviceScope,
        onResolve,
        onReject
      };
      api.processRequest(config);
    });
  }

  delete(serviceScope: string[], url: string): Promise<any> {
    return new Promise((onResolve, onReject) => {
      const config: IRequestConfig = {
        method: 'delete',
        url,
        serviceScope,
        onResolve,
        onReject
      };
      api.processRequest(config);
    });
  }

  post(serviceScope: string[], url: string, data: any): Promise<any> {
    return new Promise((onResolve, onReject) => {
      const config: IRequestConfig = {
        method: 'post',
        url,
        serviceScope,
        data,
        onResolve,
        onReject
      };
      api.processRequest(config);
    });
  }

  put(serviceScope: string[], url: string, data: any): Promise<any> {
    return new Promise((onResolve, onReject) => {
      const config: IRequestConfig = {
        method: 'put',
        url,
        serviceScope,
        data,
        onResolve,
        onReject
      };
      api.processRequest(config);
    });
  }
}

export default ApiDataApiService;
