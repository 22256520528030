import { ITaskConversation, ITaskConversationType } from 'types';
import ApiService from './api-service';
import settings from 'constants/appsettings.json';

export class ConversationService {
  protected apiService: ApiService;
  protected serviceScope: string[];

  constructor(apiService: ApiService) {
    this.apiService = apiService;
    this.serviceScope = settings.serviceScopes.TaskService;
  }

  getConversationTypes(): Promise<ITaskConversationType[]> {
    return this.apiService.get(this.serviceScope, `tasks/v1/Lookup/conversationTypes`).then((r) => r?.data);
  }

  getTaskConversations(taskId: string): Promise<ITaskConversation[]> {
    return this.apiService.get(this.serviceScope, `tasks/v1/Conversation/${taskId}/conversations`).then((r) => r?.data);
  }

  createConversation(taskConversation: ITaskConversation): Promise<ITaskConversation> {
    return this.apiService.post(this.serviceScope, `tasks/v1/Conversation`, taskConversation).then((r) => r?.data);
  }

  updateConversationIdentifier(taskConversation: ITaskConversation): Promise<void> {
    return this.apiService.put(this.serviceScope, `tasks/v1/Conversation/updateIdentifier`, taskConversation).then(() => {});
  }

  handleResponse(taskConversation: ITaskConversation): Promise<void> {
    return this.apiService.put(this.serviceScope, `tasks/v1/Conversation/outboundResponse`, taskConversation).then(() => {});
  }

  deleteTaskConversation(taskConversation: ITaskConversation): Promise<void> {
    return this.apiService.put(this.serviceScope, `tasks/v1/Conversation/removeTaskConversation`, taskConversation).then(() => {});
  }
}

export default ConversationService;
