import { IMemberMessage, IMessageRequest } from 'types';
import ApiService from './api-service';
import settings from 'constants/appsettings.json';

export class MessageService {
  protected apiService: ApiService;
  protected serviceScope: string[];

  constructor(apiService: ApiService) {
    this.apiService = apiService;
    this.serviceScope = settings.serviceScopes.MessagingService;
  }

  getConversationMessages(conversationId: string): Promise<IMemberMessage[]> {
    return this.apiService.get(this.serviceScope, `messaging/v1/Conversation/${conversationId}/messages`).then((r) => r?.data);
  }

  sendMessage(message: IMessageRequest): Promise<IMemberMessage> {
    return this.apiService.post(this.serviceScope, `messaging/v1/Conversation/messages`, message).then((r) => r?.data);
  }

  downloadMessageAttachment(filePath: string): Promise<string> {
    return this.apiService
      .post(this.serviceScope, `messaging/v1/Conversation/downloadAttachment?filePath=${encodeURI(filePath)}`, {})
      .then((r) => r?.data);
  }
}

export default MessageService;
