import { IFile } from 'packages/file-handling';
import { useRef } from 'react';
import { dispatch, setErrorMessage } from 'store';

export interface UploadController {
  inputFile: React.RefObject<HTMLInputElement>;
  onUpload: () => void;
  handleFilesUploaded: (event: React.ChangeEvent<HTMLInputElement | null>) => void;
}

export interface UploadControllerProps {
  existingFiles?: IFile[];
  onFilesUploaded: (files: IFile[]) => void;
  maxFileListSize?: number;
  maxFileSize?: number;
}

export const useUploadController = (props: UploadControllerProps): UploadController => {
  const { existingFiles = [], onFilesUploaded } = props;

  const inputFile = useRef<HTMLInputElement>(null);

  const maxFileSize = props.maxFileSize || 3145728;
  const maxFileListSize = props.maxFileListSize || 9437184;

  const onUpload = () => {
    if (inputFile.current !== null) {
      inputFile.current.click();
    }
  };

  const processUploadedFiles = (uploadedFiles: File[]) => {
    if (uploadedFiles) {
      let attachedFiles = [] as IFile[];

      uploadedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const contentBytes = reader.result as string;
          const cleanContentBytes = contentBytes.replace(/^data:.+;base64,/, '');

          let uploadedFileName = file.name;
          const existingFileNames = existingFiles.map((f) => f.fileName) as string[];
          if (existingFileNames.find((n) => file.name === n) !== null) {
            const typeIndex = file.name.indexOf('.');
            const dupeName = file.name.substring(0, typeIndex);
            uploadedFileName = `${dupeName} (1)${file.name.substring(typeIndex)}`;
          }

          attachedFiles.push({
            fileName: uploadedFileName,
            fileSize: file.size,
            contentBytes: cleanContentBytes,
            contentType: file.type
          });

          if (uploadedFiles.length === attachedFiles.length) {
            onFilesUploaded(attachedFiles);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleFilesUploaded = (event: React.ChangeEvent<HTMLInputElement | null>) => {
    if (event.target.files) {
      let eventFiles = Array.from(event.target.files).filter((attachedFile) => !attachedFile.type.includes('.exe'));

      // Check if any file size is greater than 3MB (3145728 = 3 * 1024 * 1024 bytes)
      const largeFiles = eventFiles.filter((v) => v.size > maxFileSize);
      if (largeFiles.length) {
        dispatch(setErrorMessage('File will exceed the file size limit of 3 MB.'));
        onFilesUploaded([]);
        return;
      }

      // Check if the total file size is smaller than 9MB (9437184 = 9 * 1024 * 1024 bytes)
      const combinedFilesSize = eventFiles.reduce((a, v) => (a = a + v.size), 0);
      const currentFilesSize = existingFiles.reduce((a, v) => (a = a + v.fileSize!), 0);
      if (currentFilesSize + combinedFilesSize > maxFileListSize) {
        dispatch(setErrorMessage('Files will exceed the total file size limit of 9 MB.'));
        onFilesUploaded([]);
        return;
      }

      processUploadedFiles(eventFiles);
    }
  };

  return {
    inputFile,
    onUpload,
    handleFilesUploaded
  };
};

export default useUploadController;
