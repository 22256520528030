import { api } from 'utils';
import { IMemberAppointment, IRequestConfig } from 'types';
import { dispatch } from 'store';
import {
  isLoadingLocationStates,
  isLoadingQuestions,
  isLoadingReasons,
  isLoadingTimeSlots,
  loadLocationStates,
  loadQuestions,
  loadReasons,
  loadTimeSlots
} from './appointment.slice';
import settings from 'constants/appsettings.json';

export function appointments_loadAppointments(memberId: number) {
  return new Promise((resolve, reject) => {
    const onResolve = async (res: any) => {
      const data = await res.data;

      const sortedData = data.sort((a: IMemberAppointment, b: IMemberAppointment) => {
        const aDate = new Date(a.appointmentDateTime!);
        const bDate = new Date(b.appointmentDateTime!);
        return aDate.getTime() - bDate.getTime();
      });
      resolve(sortedData);
    };

    const onReject = (err: unknown) => {
      reject(err);
    };

    const url = `appointments/v1/Appointments/${memberId}`;
    const config: IRequestConfig = {
      method: 'get',
      url: url,
      serviceScope: settings.serviceScopes.AppointmentService,
      onResolve,
      onReject
    };
    api.processRequest(config);
  });
}

export function appointments_loadQuestions() {
  dispatch(isLoadingQuestions(true));
  const onResolve = async (res: any) => {
    const data = await res.data;
    dispatch(loadQuestions(data));
  };

  const onReject = () => {
    dispatch(isLoadingQuestions(false));
  };

  const url = `appointments/v1/AppointmentComponents/questions`;
  const config: IRequestConfig = {
    method: 'get',
    url: url,
    serviceScope: settings.serviceScopes.AppointmentService,
    onResolve,
    onReject
  };
  api.processRequest(config);
}

export function appointments_loadReasons() {
  dispatch(isLoadingReasons(true));

  const onResolve = async (res: any) => {
    const data = await res.data;
    dispatch(loadReasons(data));
  };

  const onReject = () => {
    dispatch(isLoadingReasons(false));
  };

  const url = `appointments/v1/AppointmentComponents/reasons`;
  const config: IRequestConfig = {
    method: 'get',
    url: url,
    serviceScope: settings.serviceScopes.AppointmentService,
    onResolve,
    onReject
  };
  api.processRequest(config);
}

export function appointments_loadLocationStates() {
  dispatch(isLoadingLocationStates(true));

  const onResolve = async (res: any) => {
    const data = await res.data;
    dispatch(loadLocationStates(data));
  };

  const onReject = () => {
    dispatch(isLoadingLocationStates(false));
  };

  const url = `appointments/v1/AppointmentComponents/locationStates`;
  const config: IRequestConfig = {
    method: 'get',
    url: url,
    serviceScope: settings.serviceScopes.AppointmentService,
    onResolve,
    onReject
  };
  api.processRequest(config);
}

export function appointments_loadTimeSlots(locationId: number, reasonId: number) {
  dispatch(isLoadingTimeSlots(true));

  const onResolve = async (res: any) => {
    const data = await res.data;
    dispatch(loadTimeSlots(data));
  };

  const onReject = () => {
    dispatch(isLoadingTimeSlots(false));
  };

  const url = `appointments/v1/AppointmentComponents/timeslots/location/${locationId}?appointmentTypeId=${reasonId}`;
  const config: IRequestConfig = {
    method: 'get',
    url: url,
    serviceScope: settings.serviceScopes.AppointmentService,
    onResolve,
    onReject
  };
  api.processRequest(config);
}

function fetchData(url: string): Promise<any> {
  return new Promise((resolve, reject) => {
    const onResolve = (res: any) => {
      resolve(res.data);
    };

    const onReject = (err: unknown) => {
      reject(err);
    };

    const config: IRequestConfig = {
      method: 'get',
      url: url,
      serviceScope: settings.serviceScopes.AppointmentService,
      onResolve,
      onReject
    };
    api.processRequest(config);
  });
}

export function appointments_getTypes() {
  return fetchData(`appointments/v1/AppointmentComponents/types`);
}

export function appointments_getQuestions() {
  return fetchData(`appointments/v1/AppointmentComponents/questions`);
}

export function appointments_getLocations() {
  return fetchData(`appointments/v1/AppointmentComponents/locationStates`);
}

export function appointments_getTimeSlots(locationId: number, reasonId: number) {
  return fetchData(`appointments/v1/AppointmentComponents/timeslots/location/${locationId}?appointmentTypeId=${reasonId}`);
}
