import ApiService from './api-service';
import { CONVERSATION_TYPE } from 'types';
import { TemplateContainer } from 'utils/customSelectors/templates/useTemplateController';
import settings from 'constants/appsettings.json';

export class TemplateService {
  protected apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  getTemplateUrl(type: CONVERSATION_TYPE): string {
    return type === CONVERSATION_TYPE.EMAIL ? `email/v1/` : `messaging/v1/Sms/`;
  }

  getTemplateContainers(type: CONVERSATION_TYPE): Promise<TemplateContainer[]> {
    let serviceScope = type === CONVERSATION_TYPE.EMAIL ? settings.serviceScopes.EmailService : settings.serviceScopes.MessagingService;
    return this.apiService.get(serviceScope, `${this.getTemplateUrl(type)}templateContainers`).then((r) => r?.data);
  }

  getTemplateDetails(type: CONVERSATION_TYPE, templateContainer: string, templateName: string): Promise<string> {
    const containerParam = this.apiService.encodeQueryParam('templateContainer', templateContainer);
    const templateParam = this.apiService.encodeQueryParam('templateName', templateName);
    let serviceScope = type === CONVERSATION_TYPE.EMAIL ? settings.serviceScopes.EmailService : settings.serviceScopes.MessagingService;

    return this.apiService
      .get(serviceScope, `${this.getTemplateUrl(type)}template?${containerParam}&${templateParam}`)
      .then((r) => r?.data);
  }
}

export default TemplateService;
