import { IMemberEmail, IEmailRequest, IEmailAttachment } from 'types';
import ApiService from './api-service';
import settings from 'constants/appsettings.json';

export class EmailService {
  protected apiService: ApiService;
  protected serviceScope: string[];

  constructor(apiService: ApiService) {
    this.apiService = apiService;
    this.serviceScope = settings.serviceScopes.EmailService;
  }

  createEmailMessage(mailBox: string, mail: IEmailRequest): Promise<IMemberEmail> {
    return this.apiService.post(this.serviceScope, `/email/v1/${mailBox}/email`, mail).then((r) => r?.data);
  }

  getConversationEmails(conversationId: string, mailBox: string): Promise<IMemberEmail[]> {
    return this.apiService.get(this.serviceScope, `/email/v1/${mailBox}/conversation/${conversationId}/emails`).then((r) => r?.data);
  }

  deleteDraft(mailBox: string, draftId: string): Promise<undefined> {
    return this.apiService.delete(this.serviceScope, `/email/v1/${mailBox}/email/${draftId}`);
  }

  getEmailAttachments(messageId: string, mailBox: string): Promise<IEmailAttachment[]> {
    return this.apiService.get(this.serviceScope, `/email/v1/${mailBox}/email/${messageId}/attachments`).then((r) => r?.data);
  }

  getAttachmentContent(attachmentId: string, messageId: string, mailBox: string): Promise<string> {
    return this.apiService
      .get(this.serviceScope, `/email/v1/${mailBox}/email/${messageId}/attachments/${attachmentId}`)
      .then((r) => r?.data);
  }
}

export default EmailService;
