import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { display } from '@mui/system';

export enum highlightColorEnum {
  ActiveGreen = '#d0f8ab',
  InactiveRed = '#CD5C5C',
  Email = '#b2ddff',
  MemberPortal = '#ffb2b2',
  SMS = '#13c288',
  Emergency = '#FF7875',
  High = '#B37FEB',
  Routine = '#13C2C2',
  FilterButtonGray = '#505050',
  Purple = '#ddd6fe',
  Green = '#579c30',
  Gray = '#D3D3D3',
  Warning = '#ffa726'
}

interface TextHighlightProps {
  prefix?: ReactNode;
  text?: string;
  highlightColor: string;
  textColor?: string;
  display?: boolean;
  height?: string;
  fontVariant?: any;
  children?: ReactNode;
}

const TextHighlight: React.FC<TextHighlightProps> = ({
  text,
  highlightColor,
  textColor,
  prefix,
  display = true,
  height = '24px',
  fontVariant = 'body2',
  children
}) =>
  (!Boolean(text) || !display) && !children ? null : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2px 12px 2px 12px',
        gap: '3px',
        height,
        backgroundColor: highlightColor,
        color: textColor ?? '#262626',
        borderRadius: '16px'
      }}
    >
      {!children && Boolean(prefix) && prefix}
      {!children && <Typography variant={fontVariant}>{text}</Typography>}
      {children}
    </Box>
  );

export default TextHighlight;

interface ActiveTextHighlightProps extends Pick<TextHighlightProps, 'text' | 'height' | 'display'> {
  active: boolean;
}

export const ActiveTextHighlight: React.FC<ActiveTextHighlightProps> = ({ active, text, display = true, ...props }) => {
  return (
    <>
      {text && (
        <TextHighlight
          {...props}
          text={text}
          display={display}
          highlightColor={active ? highlightColorEnum.ActiveGreen : highlightColorEnum.InactiveRed}
          textColor={active ? '#262626' : 'rgba(255,255,255,0.8)'}
          prefix={active ? <CheckIcon sx={{ fontSize: 14 }} /> : <CloseIcon sx={{ fontSize: 14 }} />}
        />
      )}
    </>
  );
};
