import { IMMSFiles, ISMS, ISMSRequest } from 'types';
import ApiService from './api-service';
import settings from 'constants/appsettings.json';

interface PaginatedSMSList {
  textMessages: ISMS[];
  textMessagesCount: number;
  pageSize: number;
  pageIncrement: number;
  totalMessageCount: number;
}

export class SMSService {
  protected apiService: ApiService;
  protected serviceScope: string[];

  constructor(apiService: ApiService) {
    this.apiService = apiService;
    this.serviceScope = settings.serviceScopes.MessagingService;
  }

  getMemberTexts(memberId: number, search: string, pageIncrement: number, pageSize: number): Promise<PaginatedSMSList> {
    return this.apiService
      .post(this.serviceScope, `Messaging/v1/sms/memberSmsMessages`, {
        memberId,
        pageSize,
        pageIncrement,
        search
      })
      .then((r) => r?.data);
  }

  getConversationTexts(conversationIdentifier: string): Promise<ISMS[]> {
    return this.apiService.get(this.serviceScope, `Messaging/v1/sms/smsMessages/${conversationIdentifier}`).then((r) => r?.data);
  }

  getFilesForText(messageId: number): Promise<IMMSFiles[]> {
    return this.apiService.get(this.serviceScope, `Messaging/v1/sms/getMessageMedia?messageId=${messageId}`).then((r) => r.data);
  }

  sendText(textRequest: ISMSRequest): Promise<ISMS> {
    return this.apiService.post(this.serviceScope, `Messaging/v1/sms/sendSmsMessage`, textRequest).then((r) => r.data);
  }

  isTargetOptedOut(toNumber: ISMS['toNumber']): Promise<boolean> {
    return this.apiService.get(this.serviceScope, `Messaging/v1/sms/optOutStatus?phoneNumber=${toNumber}`).then((r) => r.data);
  }
}

export default SMSService;
