import { ITaskChatRequest, IChatMessage, ICreateChatResponse, ICreateChatRequest } from 'types';
import ApiService from './api-service';
import settings from 'constants/appsettings.json';

export class ChatService {
  protected apiService: ApiService;
  protected serviceScope: string[];

  constructor(apiService: ApiService) {
    this.apiService = apiService;
    this.serviceScope = settings.serviceScopes.ChatService;
  }

  getChatMessages(chatId: string): Promise<IChatMessage[]> {
    return this.apiService.get(this.serviceScope, `chat/v1/${chatId}/chatMessages`).then((r) => r?.data);
  }

  createChat(chatRequest: ICreateChatRequest): Promise<ICreateChatResponse> {
    return this.apiService.post(this.serviceScope, 'chat/v1/chat', chatRequest).then((r) => r?.data);
  }

  createChatMessage(chatRequest: ITaskChatRequest): Promise<IChatMessage> {
    return this.apiService.post(this.serviceScope, 'chat/v1/chatMessage', chatRequest).then((r) => r?.data);
  }

  clearChats(): Promise<void> {
    return this.apiService.post(settings.serviceScopes.TaskService, `tasks/v1/Task/cleanupTaskChats`, { maxChatCount: 20 });
  }
}

export default ChatService;
