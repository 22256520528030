import { ITeam, IRegion, IEntity, IStaffMember, IDepartment } from 'types';
import ApiService from './api-service';
import settings from 'constants/appsettings.json';

export class OrgService {
  protected apiService: ApiService;
  protected serviceScope: string[];

  constructor(apiService: ApiService) {
    this.apiService = apiService;
    this.serviceScope = settings.serviceScopes.OrgService;
  }

  getSollisRegions(): Promise<IRegion[]> {
    return this.apiService.get(this.serviceScope, `/v1/regions`).then((r) => r?.data);
  }

  getSollisDepartments(): Promise<IDepartment[]> {
    return this.apiService.get(this.serviceScope, `/v1/departments`).then((r) => r?.data);
  }

  getUsers(): Promise<IStaffMember[]> {
    return this.apiService.get(this.serviceScope, 'v1/GetUsers').then((r) =>
      r?.data.map((e: IStaffMember) => {
        return { ...e, type: 'User' };
      })
    );
  }

  getGroups(): Promise<ITeam[]> {
    return this.apiService.get(this.serviceScope, '/v1/GetGroups').then((r) =>
      r?.data.map((t: ITeam) => {
        return { ...t, type: 'Team' };
      })
    );
  }

  getTeamMembersForTeam(teamId: string): Promise<IEntity[]> {
    return this.apiService.get(this.serviceScope, `v1/GetGroupMembers/${teamId}`).then((r) =>
      r?.data.map((m: IEntity) => {
        return { ...m, type: 'TeamMember' };
      })
    );
  }

  getTeamMembersForTeams(teamIds: string[]): Promise<IEntity[]> {
    return Promise.all(teamIds.map((teamId) => this.getTeamMembersForTeam(teamId)))
      .then((responses: any[]) => {
        let teamMembers: IEntity[] = responses.flat();
        let uniqueTeamMembers = [...new Set(teamMembers)];

        return uniqueTeamMembers;
      })
      .catch(() => {
        return [];
      });
  }
}

export default OrgService;
