import useApiDataService from 'hooks/services/use-api-data-service';
import { ApiService } from 'services';
import { IMember, IReferral } from 'types';
import settings from 'constants/appsettings.json';

export class EncountersService {
  protected apiService: ApiService;
  protected serviceScope: string[];

  constructor(apiService: ApiService) {
    this.apiService = apiService;
    this.serviceScope = settings.serviceScopes.ReferralService;
  }

  getMemberReferrals(memberId: IMember['memberId']): Promise<IReferral[]> {
    return this.apiService.get(this.serviceScope, `Encounters/v1/Referral/member/${memberId}`).then((r) => r?.data);
  }
}

export const useEncounterService = () => useApiDataService<EncountersService>(EncountersService);
